body {
	background-color: hsla(0,0%,100%,1);
	padding-top: 2rem;
	padding-bottom: 2rem;
	padding-left: 2rem;
	padding-right: 2rem;
	min-height: 100vh;

	display: flex;
	align-items: center;
	justify-content: center;
}

li {
	list-style-type: circle;
	margin-bottom: .5em;
}