
.BrowseHappy {
	margin: 0.2em 0;
	background: #ccc;
	color: #000;
	padding: 0.2em 0;
}

.Main {
	color: #222;
	font-family: $f-serif;

	*:first-child {
		margin-top: 0rem;
	}
	*:last-child {
		margin-bottom: 0rem;
	}

	a {
		text-decoration: none;
	}
	a:visited {
		color: $c-blue;
	}
	a:hover,
	a:active {
		text-decoration: underline;
	}
}

.Home h1 {
	font-weight: initial;
	font-size: 1em;
}


.Band {

}

.Band_note {
	font-style: italic;
}

.AudioSample {
	height: auto;

	iframe {
		width: 100%;
		border: none;
	}
}
