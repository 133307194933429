.BtnBare {
	display: inline-flex;
	font-size: initial;
	height: auto;
	min-height: 2em;
	width: auto;
	padding-left: 0.4em;
	padding-right: 0.4em;
	padding-top: 0;
	padding-bottom: 0;
	border: 1px solid hsla(0,0%,0%,0.4);
	border-radius: 9999999px;
	background-color: transparent;
	color: hsla(0,0%,0%,1);
	cursor: pointer;
	
	align-items: center;
	justify-content: center;

	&:focus, &:hover {
		outline: none;
		background-color: hsla(0,0%,100%,0.5);
		color: hsla(0,0%,0%,1);
	}

	&:focus {
		background-color: $c-blue;
		color: hsla(0,0%,100%,1);
	}

	&:active {
		background-color: hsla(0,0%,100%,0.9);		
		color: hsla(0,0%,0%,1);
	}
}
.BtnBare-circle {
	padding: 0;
	height: 2em;
	width: 2em;
}