/*
 * Hide from both screenreaders and browsers
 */

.u-hidden {
	display: none !important;
}

.u-invisible {
	visibility: hidden !important;
}


@keyframes fadeout {
	to {
		opacity: 0;
	}
}

.u-fadeout { 
	animation: fadeout 0.6s;
}