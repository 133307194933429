@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

/*  */

.Loading img {
	opacity: 0.5;
	display: block;
	margin: 0 auto;

	animation: spin 1.6s steps(8, end) infinite;
}

.Loading_explanation {
	text-align: center;
	color: $c-grey-faint-text;
}

.Loading_retryBtn {
	/* Remove button-like styles */
	border: initial;
	background-color: initial;
	font-size: inherit;
	font-family: inherit;
	margin: initial;
	padding: initial;
	
	cursor: pointer;
	text-decoration: underline;
	color: $c-blue;
}